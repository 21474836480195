<template>
  <div>
    <header class="mb-auto" style="background-color: rgb(0, 153, 255)">
      <nav>
        <span class="navbar-brand mb-0 h1" style="color: white"
          >担当者ログインページ</span
        >
      </nav>
    </header>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <div
        v-show="
          $store.state.common.sIsLoading || $store.state.common.sDataLoading
        "
      >
        Loading...
      </div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted uk-align-left"
                    >企業ID</label
                  >
                  <input class="uk-input" type="text" v-model="alias_id" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted uk-align-left"
                    >担当者メールアドレス</label
                  >
                  <input class="uk-input" type="text" v-model="email" id="em" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted uk-align-left"
                    >パスワード</label
                  >
                  <input class="uk-input" :type="vInputType" v-model="pass" />
                  <span uk-icon="icon: file-text" @click="doSwitchPassword"
                    >パスワードを表示する</span
                  >
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-primary uk-width-1-1"
                    type="button"
                    @click="sendInfo"
                  >
                    ログイン
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p style="color: red">
        <b>{{ vMessage }}</b>
      </p>
      <router-link to="/client-sign_up">新規登録はこちら</router-link>
    </div>
  </div>
</template>

<script type="text/javascript">
// import constant from '@/constant.js'
export default {
  data() {
    return {
      email: "",
      pass: "",
      status: false,
      flg: "",
      tmp: null,
      do_something: true,
      vInputType: "password",
      alias_id: "",
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ログイン";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    if (sessionStorage.getItem("email_c")) {
      this.$store.state.alias_id = sessionStorage.getItem("alias_id");
      this.$store.state.email = sessionStorage.getItem("email_c");
      this.$store.state.pass = sessionStorage.getItem("pass_c");
      this.sendInfo(1);
    }
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    doSwitchPassword() {
      if (this.vInputType == "password") {
        this.vInputType = "text";
      } else {
        this.vInputType = "password";
      }
    },
    sendInfo(mode = 0) {
      if (!this.do_something) return;
      this.$store.commit("common/doStartServerProcess");
      this.do_something = false;

      if (mode == 1) {
        this.$axios
          .post("/client/login", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pass: this.$store.state.pass,
          })
          .then(
            function (response) {
              if (response.data.state == false) {
                this.status = response.data.state;
                this.$store.commit("setLogout_c", {});

                // alert("メールアドレスかパスワードが正しくありません ");
                this.do_something = true;
                this.$store.commit("common/doEndServerProcess");
              } else {
                this.status = response.data.state;
                this.$store.commit("setLogin_c", {
                  alias_id: this.$store.state.alias_id,
                  email: this.$store.state.email,
                  pass: this.$store.state.pass,
                  status: response.data.state,
                });
                sessionStorage.setItem("alias_id", this.$store.state.alias_id);
                sessionStorage.setItem("email_c", this.$store.state.email);
                sessionStorage.setItem("pass_c", this.$store.state.pass);
                console.log(this.$store.state.login_status_c);
                this.$store.commit("common/doLogin", {
                  vLoginId: this.$store.state.email,
                  vLoginPw: this.$store.state.pass,
                  // 'vLoginId': response.data.return_data.login_id,
                  // 'vLoginPw': response.data.return_data.com_pw,
                  vComId: response.data.return_data.com_id,
                  vComName: response.data.return_data.com_name,
                  vMailAddress: response.data.return_data.email,
                  vUserType: response.data.return_data.user_type,
                  vUserCode: response.data.return_data.user_code,
                  vUserName: response.data.return_data.user_name,
                  vRegiStatus: response.data.return_data.regi_status,
                  mode: 0,
                });
                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;
                this.$store.state.common.sDataLoading = false;
                this.$router.replace("/client-menu");
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              this.status = false;
              console.log(error);

              this.$store.commit("common/doEndServerProcess");
              this.do_something = true;
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          );
      } else if (!this.alias_id) {
        alert("企業IDを入力してください");

        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      } else if (!this.email) {
        alert("メールアドレスを入力してください");

        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      } else if (!this.pass) {
        alert("パスワードを入力してください");

        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      } else {
        if (
          this.alias_id.length > 0 &&
          this.email.length > 0 &&
          this.pass.length > 0
        ) {
          let createHash = require("sha256-uint8array").createHash;
          this.tmp = createHash().update(this.pass).digest("hex");
          console.log(this.tmp);
          this.$axios
            .post("/client/login", {
              alias_id: this.alias_id,
              email: this.email,
              pass: this.tmp,
            })
            .then(
              function (response) {
                if (response.data.state == false) {
                  this.status = false;
                  this.$store.state.login_status_c = false;

                  // alert("メールアドレスかパスワードが正しくありません ");
                  alert("入力された値のいずれかが正しくありません ");
                  this.do_something = true;
                } else {
                  this.status = response.data.state;
                  this.$store.commit("setLogin_c", {
                    alias_id: this.alias_id,
                    email: this.email,
                    pass: this.tmp,
                    state: response.data.state,
                  });
                  sessionStorage.setItem(
                    "alias_id",
                    this.$store.state.alias_id
                  );
                  sessionStorage.setItem("email_c", this.$store.state.email);
                  sessionStorage.setItem("pass_c", this.$store.state.pass);
                  console.log(this.$store.state.login_status_c);
                  this.$store.commit("common/doLogin", {
                    vLoginId: this.$store.state.email,
                    vLoginPw: this.pass,
                    // 'vLoginId': response.data.return_data.login_id,
                    // 'vLoginPw': response.data.return_data.com_pw,
                    vComId: response.data.return_data.com_id,
                    vComName: response.data.return_data.com_name,
                    vMailAddress: response.data.return_data.email,
                    vUserType: response.data.return_data.user_type,
                    vUserCode: response.data.return_data.user_code,
                    vUserName: response.data.return_data.user_name,
                    vRegiStatus: response.data.return_data.regi_status,
                    mode: 0,
                  });
                  this.$store.commit("common/doEndServerProcess");
                  this.$router.replace("/client-menu");
                }

                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;
              }.bind(this)
            )
            .catch(
              function (error) {
                this.status = false;

                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;

                console.log(error);
              }.bind(this)
            );
        } else {
          alert("内容が入力されていません");

          this.$store.commit("common/doEndServerProcess");
          this.do_something = true;
        }
      }
    },
  },
};
</script>

<style scoped>
table tr td p {
  display: inline;
}

table tr td {
  text-align: right;
}

table {
  margin: auto;
}
</style>
